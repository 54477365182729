import { ReactNode } from 'react';
import { Field, FormConfigProvider as FProvider } from '@arkejs/form';
import {
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Switch,
} from '@mui/material';
import { Json } from '@arkejs/mui';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  helperText: {
    color: 'rgba(255, 255, 255, 0.7)',
    fontWeight: 400,
    fontSize: 10,
    marginTop: 4,
    fontStyle: 'italic',
  },
}));

const dateForDateTimeInputValue = (date) => {
  if (date) {
    return new Date(
      new Date(date).getTime() + new Date().getTimezoneOffset() * -60 * 1000
    )
      .toISOString()
      .slice(0, 16);
  } else {
    return '';
  }
};

const getInputProps = (field: Field) => ({
  startAdornment: field.unit ? (
    <InputAdornment position="start" style={{ opacity: 1 }}>
      {field?.unit}
    </InputAdornment>
  ) : undefined,
  ...field.InputProps,
});

export default function FormProvider({ children }: { children: ReactNode }) {
  return (
    <FProvider
      components={{
        // @ts-ignore
        bool: ({ field }) => <BoolComponent {...field} />,
        date: ({ field }) => (
          <TextField
            {...field}
            type="date"
            onChange={(e) => field.onChange(e.target.value)}
            helperText={field?.helper_text}
            InputProps={getInputProps(field)}
          />
        ),
        datetime: ({ field }) => (
          <>
            <TextField
              {...field}
              value={dateForDateTimeInputValue(field.value ?? undefined)}
              type="datetime-local"
              fullWidth
              onChange={(e) => field.onChange(new Date(e.target.value))}
              helperText={field?.helper_text}
              InputProps={getInputProps(field)}
            />
          </>
        ),
        enum: ({ field }) => (
          <Autocomplete
            disableClearable
            {...field}
            options={field.values}
            onChange={(e, data) => field.onChange(data?.value)}
            renderInput={(params) => (
              <TextField
                helperText={field?.helper_text}
                InputProps={getInputProps(field)}
                {...params}
              />
            )}
            value={field.values.find((v) => v.value === field.value)}
            getOptionLabel={(option) => option.label?.toString() ?? ''}
            isOptionEqualToValue={(option, value) =>
              option.value?.toString() === value?.toString()
            }
          />
        ),
        json: ({ field }) => (
          <Json {...field} onChange={(_, value) => field.onChange(value)} />
        ),
        float: ({ field }) => (
          <TextField
            {...field}
            type="number"
            onChange={(e) => field.onChange(Number(e.target.value))}
            helperText={field?.helper_text}
            InputProps={getInputProps(field)}
            inputProps={{ step: field.step }}
          />
        ),
        integer: ({ field }) => (
          <TextField
            {...field}
            type="number"
            fullWidth
            onChange={(e) => field.onChange(Number(e.target.value))}
            helperText={field?.helper_text}
            InputProps={getInputProps(field)}
          />
        ),
        string: ({ field, ...rest }) => {
          if (field.values && field.values.length > 0)
            return (
              <Autocomplete
                {...field}
                options={field.values}
                onChange={(value) => field.onChange(value)}
                renderInput={(params) => (
                  <TextField
                    helperText={field?.helper_text}
                    {...params}
                    InputProps={getInputProps(field)}
                  />
                )}
                getOptionLabel={(option) => option.label?.toString() ?? ''}
                isOptionEqualToValue={(option, value) =>
                  option.value?.toString() === value?.toString()
                }
              />
            );
          return (
            <TextField
              {...field}
              value={field.value ?? ''}
              type="text"
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              helperText={field?.helper_text}
              InputProps={getInputProps(field)}
              minRows={field?.multiline ? 5 : undefined}
            />
          );
        },
        default: (props: any) => (
          <div>
            Component not found {console.log('Component not found', props)}
          </div>
        ),
      }}
    >
      {children}
    </FProvider>
  );
}

const BoolComponent = (props: Field) => {
  const { classes } = useStyles();
  return (
    <div>
      <FormControlLabel
        control={
          props.display_variant === 'switch' ? (
            <Switch
              {...props}
              type="checkbox"
              checked={props.value}
              onChange={(e) => {
                props.onChange(e.target.checked);
              }}
            />
          ) : (
            <Checkbox
              {...props}
              type="checkbox"
              checked={props.value}
              onChange={(e) => {
                props.onChange(e.target.checked);
              }}
            />
          )
        }
        label={props.label}
      />
      {props?.helper_text && (
        <div className={classes.helperText}>{props.helper_text}</div>
      )}
    </div>
  );
};
