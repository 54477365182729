import useCookie from '@/hooks/use-cookie';
import * as React from 'react';
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectClasses,
} from '@mui/material';
import { useRouter } from 'next/router';
import { makeStyles } from 'tss-react/mui';

const LOCALES_MAP = {
  it: { flag: '🇮🇹', name: 'Italiano' },
  en: { flag: '🇬🇧', name: 'English' },
};

const COOKIE_NAME = 'NEXT_LOCALE';

const useStyles = makeStyles()((theme) => ({
  selectRoot: {
    minWidth: 120,
  },
  select: {
    padding: '8px  32px 8px 16px !important',
  },
}));

export function LanguageSelect(props: { classes?: Partial<SelectClasses> }) {
  const router = useRouter();
  const { pathname, asPath, query } = router;

  const { classes } = useStyles();
  const [locale, setLocale] = useCookie(COOKIE_NAME, router.locale);

  React.useEffect(() => {
    if (router?.locale) {
      setLocale(router.locale);
    }
  }, [router?.locale]);

  if (router.locales.length === 0) return null;

  function handleChange(event: SelectChangeEvent) {
    setLocale(event.target.value);
    router.push({ pathname, query }, asPath, { locale: event.target.value });
  }

  return (
    <Select
      value={locale}
      onChange={handleChange}
      classes={{
        root: classes.selectRoot,
        select: classes.select,
        ...props.classes,
      }}
      renderValue={(value) =>
        `${LOCALES_MAP[value].flag} ${LOCALES_MAP[value].name}`
      }
    >
      {router.locales.map((item) => (
        <MenuItem key={item} value={item}>
          {LOCALES_MAP[item].flag}{' '}
          <span style={{ marginLeft: 8 }}>{LOCALES_MAP[item].name}</span>
        </MenuItem>
      ))}
    </Select>
  );
}

export function LanguageIndicator() {
  const router = useRouter();
  const [locale, setLocale] = useCookie(COOKIE_NAME, router.locale);

  return <div>{LOCALES_MAP[locale].flag}</div>;
}
